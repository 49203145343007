<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">智慧公租房</p>
				<p class="subTitle">Smart public rental housing</p>
			</div>
		</div>
		
		<div class="demand">
			<div class="text">
				<div class="line"></div>
				<p class="title">公租房现状需求</p>
				<p class="content">近年来，我国开始探索住房保障体系建设，并取得了一定成就。继廉租房、经适房和限价房后,为解决社会"夹心层"住房难问题政府提出了一个全新的住房保障模式--公共租赁住房，简称公租房。当前我国公租房建设正处于初步探索阶段，为促进我国公租房体系的自我完善,实现又好又快发展，本文对当前公租房管理中存在的若干问题作了浅析,并结合本公司科技技术手段提供了相应的解决方案。</p>
			</div>
			<img src="../../assets/imgs/pbxq.png" alt="">
		</div>
		
		<div style="background-color: #F4F8FF;">
			<div class="plan">
				<div class="text">
					<div class="line"></div>
					<p class="title">解决方案</p>
					<p class="content">天乾地坤定制开发智能管理系统，以智能锁为终端，实现公租房的房源统一管理、租约管理、房租催缴、清退等问题。操作简单方便，安全智能。最大程度减少人工管理成本。</p>
				</div>
				
				<div class="plan-img">
					<div class="img-div" v-for="item of palnArr" :key="item.id">
						<div class="top">
							<span>{{item.title}}</span>
							<img :src="item.img" alt="">
						</div>
						<p>{{item.content}}</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="door-lock">
			<div class="text">
				<div class="line"></div>
				<p class="title">公租房·智慧门锁</p>
				<p class="subTitle">NB-IOT技术高强信息覆盖</p>
				<p class="content">采用新兴的NB-IOT技术，具有覆盖广、连接多、速率低、成本低、功耗低、架构优等特点。无用户管理成本，永远在线，即使家中停电停网，门锁通讯不中断。</p>
			</div>
			
			<img src="../../assets/imgs/doorlock.png" alt="">
		</div>
		
		<div class="advantage">
			<p class="title">NB-IOT智能门锁技术优势</p>
			<div class="swiper">
				<div ref="mySwiper" class="swiper-container" id="caseSwiper"  >
				  <div class="swiper-wrapper">
				    <div class="swiper-slide my-swiper-slide" v-for="item of swiperArr" :key="item.id">
						<p class="title">{{item.title}}</p>
						<div class="line"></div>
						<p class="content">{{item.content}}</p>
					</div>
				  </div>
				  <!-- 分页器 -->
				  <!-- <div class="swiper-pagination"></div> -->
				 <!--导航器-->
				 
				</div>
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
		</div>
		
		<div style="background: #F4F8FF;">
			<div class="core">
				<p class="title">核心功能</p>
				<div class="core-content">
					<dic class="content-div" v-for="item of coreArr" :key="item.id">
						<p class="title">{{item.title}}</p>
						<div class="core-cont">
							<div>
								<p v-for="item1 of item.content">{{item1}}</p>
							</div>
							<img :src="item.img" alt="">
						</div>
					</dic>
				</div>
			</div>
		</div>
		
		<div style="text-align: center;">
			<img class="core-in" src="../../assets/imgs/core-in.png" alt="">
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import "swiper/css/swiper.css";
	export default {
		data() {
			return {
				palnArr: [
					{
						id: '1',
						title: '钥匙管理',
						content: '指纹在线下发解决海量租房管理，入住及解约不需要更换钥匙，管理方便，节约人工',
						img: require('@/assets/imgs/phplan1.png')
					},
					{
						id: '2',
						title: '房源状态',
						content: '房源的出租率&表格化、状态图、可提取打印，可直接目视化呈现，便于管理',
						img: require('@/assets/imgs/phplan2.png')
					},
					{
						id: '3',
						title: '门锁状态',
						content: '提供开门状态上报&门锁电量监测，了解租屋实时开门状态和门锁电量监测上报',
						img: require('@/assets/imgs/phplan3.png')
					},
					{
						id: '4',
						title: '租约管理',
						content: '出租房源状态进度追踪和催租；避免转租、转借',
						img: require('@/assets/imgs/phplan4.png')
					},
				],
				swiperArr: [
					{
						id: '1',
						title: '开门方式',
						content: '指纹、密码、刷卡、NB网络远程开锁'
					},
					{
						id: '2',
						title: '电子锁芯',
						content: '采用C级电子防盗锁芯，关门状态警示'
					},
					{
						id: '3',
						title: '芯片感应',
						content: '卡片升级，防克隆技术，防止复制式开锁'
					},
					{
						id: '4',
						title: '自动报警',
						content: '具备防盗报警功能，具备低电压报警功能'
					},
					{
						id: '5',
						title: '超低功耗',
						content: '稳定可靠、海量链接、无需配网、穿墙能力'
					},
					{
						id: '6',
						title: '32位处理器',
						content: '超低功耗、物联专用操作系统市面上唯一一款带操作系统的智能锁'
					},
					{
						id: '7',
						title: '指纹模块',
						content: '采用带呼吸灯式FPC 3D半导体指纹传感器，活体生物识别技术'
					},
					{
						id: '8',
						title: '锁体内置电机',
						content: '稳定，做工精细，输出效率更高'
					},
					{
						id: '9',
						title: 'APP远程解锁',
						content: 'NB-IOT通讯模式，手机端实时监控'
					},
					{
						id: '10',
						title: '支持全网通',
						content: '支持全网通NB频段IOT物联网平台'
					},
					{
						id: '11',
						title: '快速对接公租房',
						content: '校园、酒店、会所等管理平台系统'
					},
					{
						id: '12',
						title: '强大的后台管理系统',
						content: '强大的后台管理系统让管理变得高效'
					},
				],
				
				coreArr: [
					{
						id: '1',
						img: require('@/assets/imgs/core1.png'),
						title: '公租房申请审批',
						content: [
							'提交申请',
							'申请列表'
						]
					},
					{
						id: '2',
						img: require('@/assets/imgs/core2.png'),
						title: '入住协议及房租管理',
						content:[
							'收款单',
							'入住协议'
						]
					},
					{
						id: '3',
						img: require('@/assets/imgs/core3.png'),
						title: '门锁及智能设备管理',
						content:[
							'门锁授权列表',
							'门锁授权',
							'设备首页'
						]
					},
					{
						id: '4',
						img: require('@/assets/imgs/core4.png'),
						title: '维修管理',
						content:[
							'事件查看',
							'设备告警',
							'开锁记录'
						]
					},
					{
						id: '5',
						img: require('@/assets/imgs/core5.png'),
						title: '房号设置及小区管理',
						content:[
							'房源列表',
							'小区列表'
						]
					},
					{
						id: '6',
						img: require('@/assets/imgs/core6.png'),
						title: '系统基本信息',
						content:[
							'数字字典首页'
						]
					}
				]
			}
		},
		
		methods: {
			initSwiper(){
			  // eslint-disable-next-line
			  let vueComponent=this//获取vue组件实例
			  //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
			  this.currentSwiper = new Swiper('#caseSwiper', {
				
				autoHeight:'true',//开启自适应高度,容器高度由slide高度决定
				//分页器
				// pagination: {
				//   el: '.swiper-pagination',
				//   clickable:true,//分页器按钮可点击
				// },
				
				// 导航器
				navigation: {
				  nextEl: '.swiper-button-next',
				  prevEl: '.swiper-button-prev',
				},
			   
				slidesPerView: 4, //视口展示slide数1
				spaceBetween: 18
			  })
					
			},
		},
		
		mounted() {
			this.initSwiper()
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
	
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.demand {
	width: 1200px;
	margin: 0 auto;
	padding: 92px 86px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
}
.demand .text {
	position: relative;
	top: 22px;
}
.line{
	width: 25px;
	height: 3px;
	background: #184BAB;
}

.title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333
}
.demand .text .title {
	margin-bottom: 26px;
}
.demand .text .content {
	width: 541px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 22px;
}

.plan {
	width: 1200px;
	margin: 0 auto;
	padding: 119px 104px 142px;
	box-sizing: border-box;
}

.plan .text .title {
	margin-bottom: 14px;
}

.plan .text .content {
	width: 578px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 22px;
	margin-bottom: 162px;
}

.plan-img {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.img-div {
	width: 226px;
	height: 212px;
	background-color: #fff;
	padding: 12px 10px 0 22px;
	box-sizing: border-box;
}

.img-div .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.img-div .top  span {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.img-div p {
	width: 186px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 22px;
}

.door-lock {
	width: 1200px;
	margin:  0 auto;
	padding: 123px 118px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}
.door-lock .text .title {
	margin-bottom: 46px;
}

.door-lock .text .subTitle {
	height: 20px;
	font-size: 20px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 20px;
}

.door-lock .text  .content {
	width: 403px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 22px;
}

.advantage {
	width: 1200px;
	margin: 131px auto 0;
	padding: 0 114px;
	box-sizing: border-box;
}

.advantage .title {
	margin-bottom: 46px;
}

.swiper {
	position: relative;
	width: 980px;
}

.swiper-container {
	width: 846px;
	height: 198px;
}

.swiper-container .swiper-slide {
	background: #F4F8FF;
	width: 198px;
	height: 198px;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.swiper-container .title {
	height: 16px;
	font-size: 16px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 0;
}

.swiper-container .line {
	width: 10px;
	height: 4px;
	background: #184BAB;
	margin: 26px 0 14px;
}

.swiper-container .content {
	width: 169px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	text-align: center;
}

.core {
	width: 1200px;
	margin: 114px auto 0;
	padding: 42px 134px 39px;
	box-sizing: border-box;
}

.core .core-content {
	margin-top: 67px;
	padding-left: 106px;
	display: flex;
	flex-wrap: wrap;
}

.content-div {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	width: 244px;
	margin-bottom: 38px;
	padding: 26px 16px 13px 22px;
	box-sizing: border-box;
}

.content-div:not(:nth-child(3n)) {
	margin-right: 26px;
}
.content-div .title {
	font-size: 18px !important;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 13px;
}

.core-cont {
	
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.core-cont img {
	width: 92px;
	height: 92px;
	
}

.core-cont div{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 80%;
}

.core-in {
	width: 1200px;
}
</style>